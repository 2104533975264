/*import {MCHome4} from '../multiCarousel/multiCar.css';*/
import React from "react";
import { useNavigate } from "react-router-dom";
//import "../multiCarousel/multiCar.css";
//import DisplayItem from "../multiCarousel/DisplayItem";
//import ModalWinner from "../multiCarousel/ModalWinner.js";
//import ModalSiteMap from "../multiCarousel/ModalSiteMap.js";

import "./home.css";
/*import { BsInfoSquareFill } from "react-icons/bs";*/
/*import { FaCentercode } from "react-icons/fa";*/
/*import {MCHome4} from '../multiCarousel/multiCar.css';*/

function Home() {
  const navigate = useNavigate();
  //const [modalOpen, setModalOpen] = useState(false);
  //const [modalSiteMap, setModalSiteMap] = useState(false);

  /*<div className="mc1cliktoHelp"
        onClick={() => navigate('/support')}>
        <div>
          <p className="home-hours-text">Plan Your Visit</p>
        </div>
      </div>*/
  /*<div className="mc1DsplyItem" onClick={() => setModalSiteMap(true)}>
        <div>
          <p>testing</p>
        </div>
      </div>

      <div>
        {modalSiteMap && <ModalSiteMap setOpenModalSiteMap={setModalSiteMap} />}
      </div>*/
  /*<div className="mc1DsplyItem" onClick={() => setModalOpen(true)}>
        <div>
          <p className="homeDsplyItem">WE ARE HIRING A BAKER!</p>
        </div>
      </div>*/
  /*<div>{modalOpen && <ModalWinner setOpenModal={setModalOpen} />}</div>*/
  /*          style={{ marginBottom: "0px" }}*/

  const photos = [
    {
      id: "01",
      photoImg: "1.png",
    },
    {
      id: "02",
      photoImg: "2.png",
    },
    {
      id: "03",
      photoImg: "3.png",
    },
    {
      id: "04",
      photoImg: "4.png",
    },
    {
      id: "05",
      photoImg: "5.png",
    },
    {
      id: "06",
      photoImg: "6.png",
    },
    {
      id: "07",
      photoImg: "7.png",
    },
    {
      id: "08",
      photoImg: "8.png",
    },
    {
      id: "09",
      photoImg: "9.png",
    },
    {
      id: "10",
      photoImg: "10.png",
    },
    {
      id: "11",
      photoImg: "11.png",
    },
    {
      id: "12",
      photoImg: "12.png",
    },
    {
      id: "13",
      photoImg: "13.png",
    },
    {
      id: "14",
      photoImg: "14.png",
    },
  ];

  const imageList = photos.map((x) => (
    <li key={x.id} className="">
      <img
        className="homeCard"
        src={process.env.PUBLIC_URL + "/fallFest/" + x.photoImg}
      ></img>
    </li>
  ));
  return (
    <div className="entranceCntnr">
      <div className="mc1cliktoEvents" onClick={() => navigate("/support")}>
        <div>
          <p className="planyourvisit">PLAN YOUR VISIT</p>
        </div>
      </div>
      <div className="homePosterDisplay">
        <img
          className="homePoster"
          src={process.env.PUBLIC_URL + "/events/" + "LFCF_poster2a.png"}
          alt="Fall fest"
        ></img>
      </div>
      <div className="homeCardsContainer">
        <ul className="UlFestCards">{imageList}</ul>
      </div>
    </div>
  );
}

export default Home;
